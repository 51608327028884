.gneeks-buy-area {
  padding: 80px 0;
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background-size: 300% 300%;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

.pushable-white {
  background: #eeeeee;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

.front-white {
  background: #ffffff;
  display: block;
  padding: 22px 42px 15px 42px;
  border-radius: 15px;
  font-size: 3rem;
  color: #270099;
  font-weight: bold;
  transform: translateY(-6px);
  text-transform: uppercase;
  text-decoration: none;
}

.front-white:active,
.front-white:hover {
  text-decoration: none;
  color: #270099;
}

.pushable-white:active .front-white {
  transform: translateY(-2px);
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.book-content-area {
  width: 80%;
  margin-top: 5%;
  margin-left: 5%;
}

.gneeks-buy-area .gneeks-buy-area-title {
  font-size: 120px;
  margin: 0;
  padding: 0;
}

.gneeks-buy-area .gneeks-buy-area-subtitle {
  color: var(--theme-yellow);
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.gneeks-buy-area .buy-area-outline-btn-box {
  /* display: flex;
  flex-direction: row; */
}

.gneeks-buy-area .buy-area-outline-btn-box .buy-area-outline-btn {
  border: 2px solid #fff;
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-family: Andale;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  text-transform: uppercase;
}

.gneeks-buy-area .gneeks-buy-area-title {
  line-height: 100px;
  margin-bottom: 50px !important;
}

@media screen and (max-width: 760px) {
  .gneeks-buy-area .gneeks-buy-area-title {
    font-size: 22vw;
    line-height: 80px;
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .gneeks-buy-area {
    padding: 40px 0;
  }

  .gneeks-buy-area .buy-area-outline-btn-box {
    display: flex;
    flex-direction: column;
  }

  .book-content-area {
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
  }

  .pb-3 {
    font-size: 20px !important;
  }
}
