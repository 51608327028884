.new-waitlist {
  display: flex;
  flex-direction: row;
}

.new-waitlist .waitlist-outline-btn {
  border: 2px solid #fff;
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-family: Andale;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  text-transform: uppercase;
  max-width: 600px;
  margin: auto;
}

.waitlist-button-container {
  background-color: #fff;
  display: inline-block;
  /* max-width: 400px; */
  width: 100%;
  border-radius: 40px;
  padding: 2px 2px 2px 15px;
  max-width: 600px;
  margin: auto;
}
.waitlist-button-container .waitlist-input {
  padding: 5px;
  border: 0;
  flex: 2;
  font-family: Andale;
  color: #610dde;
}

.waitlist-button-container .waitlist-input::placeholder {
  text-transform: uppercase;
  font-weight: 600;
  font-family: Andale;
  color: #610dde;
}

.waitlist-button-container .waitlist-btn {
  background-color: var(--theme-yellow);
  padding: 12px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: outset 0 0 5px 0 transparent;
  border: 1px solid transparent;
}

.waitlist-button-container .waitlist-btn:active {
  color: #777;
  border: 1px solid #999;
  /*{Shadow}*/
  box-shadow: outset 0 0 5px 0 #333;
}

.connect-wallect-btn {
  background-color: var(--theme-yellow);
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 130px;
  border-radius: 4px;
}
