.gneeks-legends {
  min-height: 100vh;
  margin-top: -5%;
  padding-top: 120px;
}
.gneeks-legends .gneeks-title {
  font-size: 150px;
  margin: 0;
  padding: 0;
}

.gneeks-legends .gneeks-subtitle {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.gneeks-legends .buy-area-outline-btn-box {
  display: flex;
  justify-content: center;
}

.gneeks-legends .buy-area-outline-btn-box .buy-area-outline-btn {
  border: 2px solid #fff;
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-family: Andale;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  text-transform: uppercase;
  max-width: 400px;
}

.gneeks-legends .gneeks-legends-btn {
  background-color: #fff;
  display: inline-block;
  max-width: 500px;
  width: 100%;
  border-radius: 40px;
  padding: 2px 2px 2px 15px;
}

.gneeks-legends .gneeks-legends-btn .gneeks-legends-input {
  padding: 10px;
  border: 0;
  flex: 2;
  font-family: Andale;
  color: #610dde;
}

.gneeks-legends .gneeks-legends-btn .gneeks-legends-input::placeholder {
  text-transform: uppercase;
  font-weight: 600;
  font-family: Andale;
  color: #610dde;
}

.gneeks-legends .gneeks-legends-btn .gneeks-legends-btn-icon {
  background-color: var(--theme-yellow);
  padding: 15px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: outset 0 0 5px 0 transparent;
  border: 1px solid transparent;
}

.gneeks-legends .gneeks-legends-btn .gneeks-legends-btn-icon:active {
  color: #777;
  border: 1px solid #999;
  /*{Shadow}*/
  box-shadow: outset 0 0 5px 0 #333;
}

@media screen and (max-width: 760px) {
  .gneeks-legends .gneeks-title {
    font-size: 25vw;
  }
}
