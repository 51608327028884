.contributers_section {
  padding: 80px 0;
  overflow-x: hidden;
}
.contributers_section .gold_icon {
  width: 108px !important;
}

.contributers_section .title {
  color: #fff;
  text-align: center;
}

.contributers_section .description {
  color: #f9e805;
}

.d-block {
  text-align: left;
}

@media screen and (max-width: 480px) {
  .ui-layout-column-center {
    text-align: left !important;
  }
}
