.landing_story {
  background-color: var(--bs-node-blue);
}

.landing_story .story_bg {
  padding: 80px 0 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing_story .story_bg .story_image_container {
  padding: 0 1%;
}
.landing_story .story_bg .story_content_container {
  padding: 15% 4%;
}

.buy-area-title {
  margin-top: -40px;
  font-size: 70px;
  line-height: 60px;
  text-transform: uppercase;
}

.center-text {
  text-align: center;
}

.buy-area-sub-title {
  font-size: 30px;
}

@media screen and (max-width: 525px) {
  .buy-area-title {
    margin-top: -40px;
    font-size: 40px !important;
    line-height: 40px;
    text-transform: uppercase;
  }
  .buy-area-sub-title {
    font-size: 18px;
    font-weight: lighter;
  }
  .landing_story .story_bg .story_content_container {
    padding: 10% 4%;
  }
  .story-copy {
    font-size: 18px;
    line-height: 28px;
  }
}

.landing_story .story_bg .story_content_container .description {
  font-size: 17px;
  width: 100%;
}
.landing_story .story_bg .story_logos {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.landing_story .story_bg .available_text {
  display: flex;
  align-items: center;
  gap: 10px;
}
.phantom-logo {
  margin-top: -5px;
}

.landing_story .story_bg .wallet_btn_box {
  display: flex;
  align-items: center;

  /* flex: 1; */
}
.landing_story .story_bg .wallet_btn_box .connect_wallet_btn {
  background-image: linear-gradient(#f0c70c, #fffc0e);
  padding: 20px 30px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 35px;
  font-family: 'teko-medium';
  text-transform: uppercase;
}
.landing_story .story_bg .cover_image {
  height: 100%;
}

@media screen and (max-width: 760px) {
  .landing_story .story_bg {
    background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/purple-wall-mobile.jpg');
    background-repeat: no-repeat;
    background-size: 400px 766px !important;
  }
  .landing_story .story_bg .cover_image {
    width: 100%;
    margin-top: -30px;
  }
  .buy-area-title {
    font-size: 60px;
  }
  .landing_story .story_bg h2 {
    margin-top: -20px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 525px) {
  .buy-area-title {
    font-size: 50px;
  }
}
