.samples_section {
  padding: 100px 0 0 0;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 30%, var(--theme-purple) 100%);
}

.samples-heading {
  font-size: 120px;
  padding-bottom: 40px;
}

.samples_section .title {
  font-size: 80px;
}
.samples_section .logo {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .samples_section .title {
    font-size: 24px;
  }

  .shim-up-20 {
    margin-top: -20px;
  }
}
