.main_card {
  border: 2px solid rgb(202, 202, 202);
  min-height: 450px;
  padding: 8%;
  margin: 5%;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  max-width: 500px;
}
.main_card:hover {
  border: 2px solid yellow;
  background: rgba(0, 0, 0, 0.5);
}
.main_card .card_title {
  font-family: 'teko-medium';
}

.coin_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.card_portrait {
  clip-path: inset(20px);
  /* border: 1px solid red; */
}
