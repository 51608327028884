.modal-waitlist {
  max-width: 400px;
  margin: 0 auto;
}

.modal-waitlist .text-input {
  padding: 14px;
  width: 100%;
  border-radius: 4px;
  background-color: rgb(250, 250, 250);
  border: 1px solid rgb(180, 180, 180);
}

.modal-waitlist .submit-btn {
  background-color: var(--bs-blue);
  width: 100%;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
}
