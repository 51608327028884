.gneeks-hero {
  background-color: var(--theme-purple);
  min-height: 100vh;
  padding-top: 250px;
  padding-bottom: 4%;
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

.hero-cap {
  width: 60%;
  padding: 150px 0;
  margin: 0px auto;
  text-align: center;
}

.hero-paragraphs {
  padding-bottom: 30px !important;
  font-size: 17.5px !important;
  padding-left: 15px !important;
}

.proof-heading {
  width: 80%;
  padding-bottom: 10%;
}

.proof-image {
  width: 70%;
}

.proof-image-shim-bottom {
  margin-bottom: -10%;
}

.home-owl-character {
  margin-top: -10%;
}

.gneeks-hero p {
  font-size: 19px;
}

.gneeks-hero .gneeks-title {
  font-size: 190px;
  margin: 0;
  padding: 0;
}

.gneeks-title {
  margin-top: -5% !important;
}

.gneeks-hero .gneeks-subtitle {
  color: var(--theme-yellow);
  /* text-align: center; */
  margin: 0;
  padding: 60px 0 2px 0 !important;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: bold;
  font-size: 30px;
}

.gneeks-hero .gneeks-hero-btn {
  background-color: #fff;
  display: inline-block;
  /* max-width: 400px; */
  width: 100%;
  border-radius: 40px;
  padding: 2px 2px 2px 15px;
}

.gneeks-hero .gneeks-hero-btn .gneeks-hero-input {
  padding: 10px;
  border: 0;
  flex: 2;
  font-family: Andale;
  color: #610dde;
}

.gneeks-hero .gneeks-hero-btn .gneeks-hero-input::placeholder {
  text-transform: uppercase;
  font-weight: 600;
  font-family: Andale;
  color: var(--theme-blue) !important;
}

.gneeks-hero .gneeks-hero-btn .gneeks-hero-btn-icon {
  background-color: var(--theme-yellow);
  padding: 15px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: outset 0 0 5px 0 transparent;
  border: 1px solid transparent;
}

.gneeks-hero .gneeks-hero-btn .gneeks-hero-btn-icon:active {
  color: #777;
  border: 1px solid #999;
  /*{Shadow}*/
  box-shadow: outset 0 0 5px 0 #333;
}

.uline {
  text-decoration: underline;
  text-decoration-style: dotted;
  color: yellow;
}

.roadmap-full {
  color: #fff;
  font-weight: normal;
  font-family: andale;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: 40px !important;
}

@media screen and (max-width: 480px) {
  .proof-image {
    width: 80% !important;
  }

  .hero-paragraphs {
    width: 95%;
    font-size: 20px !important;
    padding-left: 17px !important;
    padding-bottom: 30px !important;
  }
}

@media screen and (max-width: 760px) {
  .gneeks-hero .gneeks-title {
    font-size: 28vw;
  }

  .home-owl-character {
    margin-top: 0%;
  }

  .gneeks-hero .gneeks-subtitle {
    font-size: 25px;
  }

  .gneeks-hero {
    padding-top: 100px;
  }

  .hero-cap {
    width: 90%;
    padding: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 0px auto;
    text-align: center;
    font-size: 14px !important;
  }

  .buy-box {
    width: 100%;
    padding-left: 2em;
    padding-right: 1em;
  }
}
