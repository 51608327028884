.gaming_hero {
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);

  padding: 15% 0 5% 0;
  margin-top: -10%;
}
.caption-this {
  width: 50%;
  text-align: center;
  margin: 0px auto;
  padding-bottom: 50px;
}
.gaming_hero .logo {
  width: 500px;
}

.gaming_hero .description {
  font-size: 40px;
}
.gaming_hero .btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1% 0;
}

.gaming_hero .btn_container button {
  font-size: 30px;
  display: block;
}

@media screen and (max-width: 480px) {
  .gaming_hero .btn_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 1% 0;
  }
  .owl-theme .owl-nav.disabled + .owl-dots {
    display: none;
  }
  .caption-this {
    width: 80%;
    text-align: center;
    margin: 0px auto;
    padding-bottom: 50px;
    font-size: 19px;
  }
  .gaming_hero .description {
    width: 280px;
    text-align: center;
    margin: 0px auto;
    line-height: 30px !important;
    color: yellow !important;
  }
  .gaming_hero .logo {
    width: 200px;
    margin-top: 15%;
  }
  .gaming_hero .description {
    font-size: 30px;
    line-height: normal;
    padding: 10% 5% 0% 5% !important;
  }
}
