.sogo_section {
  padding: 10% 0;
  margin-top: 5%;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 30%, var(--theme-purple) 100%);
}

.gold_icon {
  width: 108px !important;
}

.text-yellow {
  padding-top: 30px !important;
}
