.board-card {
  padding: 20px 0;
  cursor: pointer;
}

.board-card-title {
  margin-top: -40px;
}

.board-modal-text {
  font-size: 13px;
  padding: 20px 20px;
}

.board-card .board-card-title {
  font-size: 18px;
  padding: 5px;
  margin: 0;
  font-weight: bold;
}

.board-modal-btn {
  background-color: var(--theme-yellow);
  color: #000;
  border: 0;
  text-transform: uppercase;
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  width: 100%;
}

.modal-header-board {
  display: flex;
  justify-content: flex-end;
}

.board-modal-content {
  width: 100%;
}

@media screen and (max-width: 760px) {
  .board-card .board-card-title {
    margin-top: 5% !important;
  }
}
