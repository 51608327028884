/* .mint-dapp-wrapper {
  padding: 1.5em;
} */

.form-check {
  color: lightgrey;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.dropdown-font {
  color: white;
  font-weight: bold;
  font-size: 18px;
}

.dropdown-font-already-minted {
  color: lightgrey;
  font-weight: bold;
  font-size: 16px;
}

.dropdown-toggle {
  border-color: rgb(198, 198, 198);
  background-color: transparent;
  padding-top: 2px;
  padding-bottom: 2px;
}

.mint-settings-row {
  border-bottom: 1px solid lightgrey;
  align-items: center;
  justify-content: flex-start;
}

.button-front {
  display: block;
  padding: 22px 42px 15px 42px;
  margin-top: 6px;
  border-radius: 15px;
  font-size: 2.7rem;
  color: rgb(206, 195, 195);
  font-weight: bold;
  transform: translateY(-6px);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.button-back {
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  width: 100%;
  max-width: 600px;
  /* margin: 0 auto; */
  margin-top: 14px;
}

.button-sold-out {
  opacity: 0.7;
}

.center {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.connect-button-front {
  background: #fffc70;
  color: #270099;
}

.connect-button-back {
  background: #c8b93b;
}

.mint-button-front {
  background: #ef165a;
  color: white;
}

.mint-button-back {
  background: #9d0034;
}

.mint-text {
  color: white;
  margin: 0px;
}

.connected-label {
  font-size: 20px;
  padding-top: 24px;
  font-family: courier;
  font-weight: lighter;
  color: var(--theme-yellow);
}

.active-address-label {
  font-size: 20px;
  /* padding-top: 24px; */
  font-family: courier;
  font-weight: lighter;
  color: var(--theme-yellow);
}

.phase-label {
  font-size: 26px;
  font-family: courier;
  font-weight: bold;
  color: var(--theme-yellow);
}

.phase {
  font-size: 56px;
  letter-spacing: 1.5px;
}

.phase-message {
  font-size: 38px;
}

.soon {
  font-size: 28px;
  letter-spacing: 1.5px;
}

.available {
  font-size: 22px;
  /* padding-bottom: 6px; */
}

.sold-out-message {
  font-size: 18px;
  /* padding-bottom: 6px; */
}

.countdown {
  font-size: 17px;
  font-weight: normal;
  /* padding-bottom: 6px; */
}

.already-minted {
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 4px;
}

.address-text {
  font-size: 14px;
  color: rgb(193, 193, 193);
  font-weight: 600;
  padding-top: 0px;
}

.box-border {
  padding: 0px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.mint-dropdown {
  padding-right: 8px;
}

.buy-now {
  padding-bottom: 22px;
}

.mint-picker-row {
  justify-content: flex-start;
}

.mobile-detected {
  color: rgb(255, 0, 0) !important;
  margin-top: 20px;
  padding: 4px;
  background-color: white;
  opacity: 0.9;

  border-radius: 15px;
}

.metamask-link {
  color: rgb(0, 26, 255) !important;
  padding-top: 8px;
}
