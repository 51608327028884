.whitepaper_container {
  background-color: #ffffff;
  padding: 0 2%;
  /* display: flex; */
  max-width: 100%;
  overflow-x: hidden;
}

.whitepaper_container .content {
  /* width: 50vw !important; */
  width: 100%;
}

.paper-nav {
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

.paper-links {
  color: blue;
}
.whitepaper_container h1 {
  word-wrap: break-word;
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-kerning: normal;
  font-size: 40px;
  font-weight: 700;
}

.whitepaper_container ul li {
  color: #000;
}
.whitepaper_container h3 {
  padding: 10px 0px 10px 0px;
}
.whitepaper_container h4 {
  padding: 0px 0px 20px 0px;
  margin-top: -20px;
  font-weight: bolder;
}

#phases-list li {
  list-style: none;
  padding: 0px 0px 0px 40px;
}

.whitepaper_container h1,
h2 {
  text-rendering: optimizeLegibility;

  line-height: 1.1;
  margin: 0 0 1.45rem;
  padding: 0;
}

p {
  color: rgb(51, 51, 51);
}

.whitepaper_container h1 {
  font-size: 3rem;
  line-height: 1.4;
  margin: 2rem 0px;
}

.whitepaper_container h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.whitepaper_container h2 {
  font-size: 2rem;
  line-height: 1.4;
  margin: 3rem 0px 2rem;
  font-weight: 600;
  scroll-margin-top: 4.75rem;
}

.reactMarkDown ul {
  margin-left: 5%;
}
.whitepaper_container .navigation {
  position: fixed;
  right: 0;
  background-color: #fff;

  /* margin-top: 20px; */
  padding: 2% 4%;
  color: #fff !important;
  height: 80vh;
  overflow-y: scroll;
  top: 0;
  margin-top: 150px;
  /* direction: rtl; */
  /* width: 100%; */
  /* max-width: 500px; */
}
.whitepaper_container .navigation ol {
  list-style: none;
  margin: 0;
  border-left: 1px solid #bbb;
  /* margin: 10px 0; */
}

.whitepaper_container a {
  color: var(--bs-node-light-blue);
}

.whitepaper_container .navigation ol li ul {
  list-style: none;
  margin-left: 8%;
  padding: 0;
}
.whitepaper_container .navigation ol li {
  color: #000 !important;
  padding: 4px 0;
  font-size: 14px;
}
.whitepaper_container .navigation::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.whitepaper_container .navigation::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.whitepaper_container .navigation::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.whitepaper_container .navigation::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.whitepaper_container .content_sidebar_mobile {
  display: none;
}
.whitepaper_container .content_sidebar_mobile .content_sidebar_mobile_header {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0;
  border: 1px solid rgb(212, 212, 212);
}

.whitepaper_container .content_sidebar_mobile .content_sidebar_mobile_header p {
}
.show_content {
  display: block;
}
.show_content ol {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.show_content ol li ul {
  margin-left: 4%;
}

.hide_content {
  display: none;
}

.breakd {
  display: none;
  visibility: hidden;
}
@media screen and (max-width: 1024px) {
  .whitepaper_container .navigation {
    display: none;
  }
  .whitepaper_container .content_sidebar_mobile {
    display: block;
  }
  .breakd {
    display: block;
    visibility: visible;
  }
}

@media screen and (max-width: 480px) {
  .col-12 {
    /* width: 50vw !important; */
    width: 100%;
    padding: 0% 8%;
  }
  .paper-nav {
    height: 100px !important;
  }
}

.wtree li {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
}
.wtree li:before {
  content: '';
  counter-increment: item;
  position: absolute;
  top: -10px;
  left: -30px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 30px;
  height: 15px;
}
.wtree li:after {
  position: absolute;
  content: '';
  top: 5px;
  left: -30px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 30px;
  height: 100%;
}
.wtree li:last-child:after {
  display: none;
}
.wtree li span {
  display: block;
  border: 1px solid #ddd;
  padding: 10px;
  color: #666666;
  text-decoration: none;
}
.wtree li span:before {
}

.wtree li span:hover,
.wtree li span:focus {
  color: #000;
  border: 1px solid #474747;
}
.wtree li span:hover + ol li span,
.wtree li span:focus + ol li span {
  color: #000;
  border: 1px solid #474747;
}
.wtree li span:hover + ol li:after,
.wtree li span:hover + ol li:before,
.wtree li span:focus + ol li:after,
.wtree li span:focus + ol li:before {
  border-color: #474747;
}

li span {
}

li li span {
}

li li li span {
}

.display {
  margin-right: 12px;
  font-weight: bold;
}

label {
  padding-left: 6px;
  padding-right: 12px;
}

#radio-1:checked + label ~ ol li ol li {
  display: none;
}

#radio-2:checked + label ~ ol li ol li ol li {
  display: none;
}

#radio-3:checked + label ~ li ol li ol li {
  display: none;
}
