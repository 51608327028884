.partners {
  background-color: var(--bs-node-dark);
  padding: 7% 0;
}

.partners .logo_containers {
  padding: 0 10%;
}
@media screen and (max-width: 760px) {
  .partners {
    background-color: var(--bs-node-dark);
    padding: 0;
    padding-top: 7%;
  }
  .partners .logo_containers {
    padding: 0;
  }
}
