.app_hero {
  background-color: var(--theme-purple);

  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  padding-top: 120px;
}
.app_hero .app_hero_container {
  padding: 80px 0;
}
.app_hero .app_hero_container .app_hero_title {
  font-size: 60px;
  line-height: 50px;
  margin: 0;
  color: #000;
}
.app_hero .app_hero_container .app_heo_sub_heading {
  font-size: 25px;
  margin: 50px auto;
}

.on_phone_small_text_green {
  font-size: 15px;
  margin-top: -3%;
}

.app_hero .app_hero_container .app_hero_small_text {
  font-size: 20px;
  max-width: 600px;
  margin: 50px auto;
}

.app_hero .app_stores_links {
  display: flex;
  justify-content: center;
  gap: 20px;
}
@media only screen and (max-width: 760px) {
  .app_hero {
    padding-top: 90px;
  }
  .app_hero .app_hero_container .app_hero_small_text {
    font-size: 16px;
  }
  .app_hero .app_hero_container .app_hero_title {
    font-size: 25px;
    line-height: 20px;
    margin: 0;
  }
}
