@media screen and (max-width: 480px) {
  .center-mobile {
    text-align: center !important;
    margin: 0px auto;
  }

  .ui-section-customer--logo {
    width: 150px !important;
  }
}
