.on_phone {
  background-color: var(--theme-purple);

  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  padding-top: 120px;
  padding: 80px 0;
}
.on_phone .on_phone_title {
  font-size: 60px;
  line-height: 50px;
  margin: 0;
}
.on_phone .on_phone_mob_img {
  margin: 6% auto;
}
.on_phone .on_phone_small_text {
  font-size: 18px;
  max-width: 600px;
  margin: 50px auto;
}

.on_phone .app_stores_links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.on_phone .on_phone_small_text_green {
  font-size: 16px;
  max-width: 650px;
  margin: 50px auto;
}

@media only screen and (max-width: 760px) {
  .on_phone {
    padding: 60px 0;
  }
  .on_phone .on_phone_title {
    font-size: 25px;
    line-height: 20px;
    margin: 0;
  }
  .on_phone .on_phone_small_text {
    font-size: 16px;
  }
}
