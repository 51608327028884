.dash_navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #151719;
  padding: 0 10px;
  min-height: 65px;
  border-bottom: 0.5px solid #7f7f80;
  z-index: 1;
  width: 100%;
  height: 70px;
  /* position: fixed; */
}

.dash_nav-menu {
  display: flex;
}

.dash_nav_item {
  border-left: 1px solid #7f7f80;
  padding: 7px 10px;
}
.coin_value_box {
  display: flex;
  align-items: center;
  gap: 5px;
}
.coin_value {
  font-size: 18px;
  font-weight: bold;
}

.yellow_text {
  color: #f6e04c;
}
.dash_nav_bar_icon {
  color: #fff;
  font-size: 30px;
}
.dash_nav_icon {
  color: #fff;
  font-size: 30px;
}

.side_bar {
  min-height: 100vh;
  /* margin-top: 70px; */
  display: flex;
  /* position: fixed; */
  z-index: 1;
  background-color: #15171b;
  /* left: 0px; */

  padding: 5px;
  z-index: 1;
  /* position: fixed; */
}

.side_nav_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}

.side_nav_icon_box {
  display: flex;
  flex-direction: column;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.link_text {
  text-align: center;
  font-size: 14px;
}

.sub_side_nav {
  background-color: #191d21;
  /* position: fixed; */
  /* position: absolute;
  left: 82px; */
  height: 91vh;
  /* padding: 0 20px; */
  /* margin-top: 70px; */
  overflow-y: scroll;
  /* border: 1px solid #fff; */
  width: 250px;
  /* margin-top: ; */
  /* top: 0; */
  z-index: 1;
}

.sub_side_nav::-webkit-scrollbar {
  display: none;
}
.sub_side_nav ul {
  list-style: none;
  margin: 0;
}

.sub_side_nav ul .sub_nav_item {
  padding: 5%;
  border-bottom: 1px solid #14171b;
  font-weight: bold;
}
.sub_side_nav ul .sub_nav_item:hover .sub_item_header {
  color: yellow;
}
.sub_side_nav ul .sub_nav_item_active {
  background-color: rgb(77, 77, 77);
}
.sub_item_context {
  padding: 0 10px;
}

.sub_item_context ul {
  margin: 0;
  list-style: none;
}
.sub_item_context ul li {
  font-size: 14px;
  padding: 2px 5px;
  font-weight: normal;
  display: flex;
  gap: 4px;
  align-items: center;
}

.sub_side_nav ul .sub_nav_item .sub_item_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
}
.name_link {
  color: #fff !important;
  text-decoration: none !important;
}

.name_link:hover {
  color: yellow !important;
}
.mobile {
  display: none;
}
@media screen and (max-width: 760px) {
  .dekstop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .dash_nav_icon {
    font-size: 25px;
  }
}

.main_container {
  overflow-y: scroll;
  height: 100vh;
  background: #15171b;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(41, 41, 41);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #535353;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #535353;
}
