.teasure {
  background-color: #15171b;
  background-size: 100%;
}
.teasure_bg {
  position: absolute;
  margin-top: 70px;
}
.teasure_logo {
  min-width: 200px;
  max-width: 500px;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .teasure_bg {
    display: none;
  }
}
