.moments {
  padding: 10% 0 15% 0%;
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 30%, var(--theme-purple) 100%);
}
.moments_section {
  /* margin: auto; */
  /* width: calc(100vw - 240px); */
  /* clip-path: inset(-100vw 8vw -100vw 8vw); */
}
.comp-text {
  margin-top: -20px;
}

.moment_slide {
  width: 100%;
}
.moment_slide img {
  border-radius: 10px;
  border-bottom: 2px solid transparent;
  width: 100%;
}
.moment_slide ._content {
  border-bottom: 2px solid transparent;
}

.moment_slide ._content:hover {
  border-bottom: 2px solid yellow;
}

._content {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

._content ._content-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  border-radius: 10px;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

._content:hover ._content-overlay {
  opacity: 1;
}

._content-image {
  width: 100%;
}

._content-details {
  position: absolute;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 70%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

._content:hover ._content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
