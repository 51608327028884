footer {
  background: #150a48;
}
.contentinfo {
}

.footer-node {
  width: 150px;
}
.footer__layout {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.ui-text-note {
  text-align: center;
  padding: 0px 0px 20px 0px;
}
/* .footer-node {
  display: inline;
} */

.tiny-font {
  font-size: 8px;
}
@media screen and (max-width: 480px) {
  .footer-node {
    width: 130px !important;
  }
  .footer__layout {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
