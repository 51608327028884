.undergrounds_section {
  background: #15171b;
  padding: 5% 0;
}
.undergrounds_section .gold_icon {
  width: 108px !important;
}

.undergrounds_section .cards_ {
  display: flex;
  align-items: center;
}
.undergrounds_section .title {
  color: #fff;
  text-align: center;
}

.undergrounds_section .description {
  color: #f9e805;
  text-align: center;
}

.undergrounds_section .text-content {
  display: flex;
  justify-content: flex-end;
  margin-top: 5%;
}

.undergrounds_section .text-content p {
  border-left: 4px solid #fff;
  padding: 0 1%;
  margin: 2% 0;
  font-size: 25px;
}

@media screen and (max-width: 760px) {
  .undergrounds_section .cards_ {
    display: block;
  }
  .undergrounds_section .cards_ .arrow_buttons {
    display: none;
  }
}
