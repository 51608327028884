.new-waitlist {
}

.pushable {
  background: #c8b93b;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

.front {
  background: #fffc70;
  display: block;
  padding: 22px 42px 15px 42px;
  border-radius: 15px;
  font-size: 3rem;
  color: #270099;
  font-weight: bold;
  transform: translateY(-6px);
  text-transform: uppercase;
}

.pushable:active .front {
  transform: translateY(-2px);
}

.new-waitlist-modal {
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/modal-bg-desktop.jpg');
  padding: 50px;
  object-fit: cover;
  background-position: 0% 40%;
  background-repeat: no-repeat;
}

.new-waitlist-modal .title {
  font-size: 80px;
}

.new-waitlist-modal .modal-form-waitlist {
  padding: 60px 0;
}

.new-waitlist-modal .input-field {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
  font-family: Andale;
}

.new-waitlist-modal .input-field::placeholder {
  text-transform: uppercase;
  color: #000;
  font-family: Andale;
}

.new-waitlist-modal .input-field:-ms-input-placeholder {
  text-transform: uppercase;
  color: #000;
  font-family: Andale;
}

.new-waitlist-modal .input-field::-ms-input-placeholder {
  text-transform: uppercase;
  color: #000;
  font-family: Andale;
}

@media screen and (max-width: 480px) {
  .new-waitlist .pushable .front {
    font-size: 1.5rem !important;
  }

  .front_small {
    font-size: 1.5rem !important;
  }

  .new-waitlist-modal {
    background-image: none;
    background-size: 100% 100%;
    padding: 30px 10px;
  }

  .new-waitlist-modal .modal-form-waitlist {
    padding: 20px 0;
  }

  .new-waitlist-modal .title {
    font-size: 40px !important;
  }
}
