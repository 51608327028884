._navbar {
  background: #0c1217;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  padding: 1% 0;
  position: relative;
  width: 100%;
  z-index: 1;
  width: 100% !important;
}

._navbar_logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}
._logo {
  width: 70px;
}

.nav-menu {
  display: flex;
  gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: flex-end;
  margin-right: 2rem;
}
.nav-menu .nav-item {
  display: flex;
  align-items: center;
  height: 50px;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.toggle_btn {
  display: none;
  margin: 2%;
  background: transparent;
  outline: none;
  border: 0;
}

.nav-icon {
  color: #fff;
}

.rs-drawer-body {
  background-color: rgb(73, 73, 73);
  padding: 20% 10% !important;
}

@media screen and (max-width: 960px) {
  ._navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    background: #ffffff;
    font-size: 1.2rem;
    background: #1a1e36;
    position: relative;
    padding: 2% 5%;
    z-index: 1;
    margin: 0 auto;
  }

  ._logo {
    width: 70px;
  }
  ._icon {
    color: #000;
  }

  .toggle_btn {
    display: block;
  }
  .nav-menu {
    display: none;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .button {
    display: none;
  }
}
