.legendary_section {
  background: var(--bs-node-dark);
  padding: 5% 0;
}
.legendary_section .gold_icon {
  width: 108px !important;
}

.legendary_section .title {
  color: #fff;
  text-align: center;
}

.legendary_section .description {
  color: #f9e805;
  text-align: center;
}
