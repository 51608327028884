.popover {
  padding: 10px;
  background-color: var(--bs-node-dark) !important;
  min-width: 200px;
  /* width: 100%  */
}

.poppin-p {
  font-size: 14px;
  text-align: left;
}

.pop {
  display: inline;
}

.pop .pop-content {
  position: absolute;
  background-color: #000;
  z-index: 999;
  max-width: 400px;
  padding: 20px !important;
  margin: 0 auto;
  margin-left: 3%;
}

@media screen and (max-width: 480px) {
  .pop .pop-content {
    position: absolute;
    background-color: #000;
    z-index: 100;
    max-width: 300px;
    padding: 10px;
    margin-left: 11%;
  }
  .poppin-p {
    font-size: 12px;
  }
}
