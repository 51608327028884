.hacker-way-hero {
  padding-top: 300px;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  padding-bottom: 150px;
}

.hacker-way-hero .gneeks-title {
  font-size: 150px;
  margin: 0;
  padding: 0;
}

.hacker-way-hero .gneeks-subtitle {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .hacker-way-hero .gneeks-title {
    font-size: 12vw;
  }
  .preview_section .container {
    margin-top: 0% !important;
  }
  .samples-heading {
    font-size: 10vh;
    line-height: 70px;
  }
  .gneeks-title {
    line-height: 70px;
    margin: 5% 0 5% 0 !important;
  }

  .hacker-way-hero {
    padding-top: 200px;
    padding-left: 20px;
    padding-right: 20px;
  }
}


.hacker_way_hero_new .video_placeholder {
  cursor: pointer;
}
.hacker_way_hero_new .video_box {
  padding-top: 150px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 760px) {
  .hacker_way_hero_new .video_box {
    padding: 100px 0;
  }
  .hacker_way_hero_new .video_box .hacker_way_hero_new_small_text {
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .hacker_way_hero_new .hacker_way_hero_new_title {
    font-size: 25px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 50px;
  }
  .hacker_way_hero_new .video_box .hacker_way_hero_new_sub_heading {
    font-size: 20px;
  }
}

.video-subtitle-shim {
  margin-top: -50px !important;
}

@media only screen and (max-width: 450px) {
  .hacker_way_hero_new .video_box {
    padding: 100px 0;
  }
  .subtitle-shim {
    margin-top: -100px !important;
  }
}

.player-wrapper {

  position: relative;
  padding-top: 46.25%; /* 720 / 1280 = 0.5625 */

}

.react-player {
  position: absolute;
    padding-left: 10%;
    padding-right: 10%;
  top: 0;
  left: 0;
}
