.landing_hero {
  /* min-height: 100vh; */
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/bg-purple-wall.jpg');
  background-size: cover;
  padding-top: 100px;
}
.landing_hero .logo {
  width: 130px;
}

.landing_hero .title {
  font-family: 'teko-medium';
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  padding: 2% 0;
}

.landing_hero .cards_section {
  display: flex;
  align-items: center;
}
.carousel-container {
  text-align: center;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 760px) {
  .landing_hero {
    min-height: 100vh;
    background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/website/assets/home/hero-bg-mobile.jpg');
    background-size: 100% 100%;
  }
  .landing_hero .cards_section {
    display: block;
  }
  .landing_hero .cards_section .arrow_buttons {
    display: none;
  }
}
