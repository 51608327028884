.features {
  background-color: var(--bs-node-dark);
  padding: 10% 0;
}
.features .description {
  font-size: 40px;
}

.heading-classx-logo {
  width: 150px;
}

.moments-row {
  margin-top: 10px;
}

.features-area {
  margin-top: 5%;
}

.paragraph {
  padding-top: 60px;
}
.my-5 {
  padding-top: 20%;
}

@media screen and (max-width: 480px) {
  .features-area {
    text-align: left;
  }
  .features {
    padding: 15% 0;
  }
  .paragraph {
    padding-top: 0px;
  }
  .ui-component-list {
    padding-left: 0%;
  }
  h2 {
    text-align: left !important;
  }
  .width-shim-80 {
    width: 90%;
    text-align: center;
    margin: 0px auto;
    padding-bottom: 30px;
  }
  .classxlogofull {
    width: 120px;
    padding-bottom: 20px;
  }
  .w-100 {
    width: 400px !important;
  }

  .display-5 {
    padding: 0px;
  }
}
