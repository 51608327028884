.timeline-container {
  background-color: var(--bs-node-dark);
  padding: 80px 0;
}
.timeline-container .logo {
  width: 80px;
  margin-bottom: 60px;
}
.timeline-container h1 {
  font-size: 60px;
  text-align: center;
}
.timeline {
  position: relative;
  margin: 10px auto;
  padding: 40px 0;
  width: 1150px;
  box-sizing: border-box;
}
.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #c5c5c5;
}
.timeline ul {
  padding: 0;
  margin: 0;
}
.timeline ul li {
  list-style: none;
  position: relative;
  width: 50%;
  padding: 20px 40px;
  box-sizing: border-box;
}
.timeline ul li:nth-child(odd) {
  float: left;
  text-align: right;
  clear: both;
}
.timeline ul li:nth-child(even) {
  float: right;
  text-align: left;
  clear: both;
}
.time-content {
  padding-bottom: 20px;
}
.time-content .timeline_description {
  color: #fff;
  font-size: 16px;
}
.time-content .status {
  color: var(--bs-node-yellow);
  font-size: 16px;
  font-weight: 600;
}
.time-content .statusprogress {
  color: var(--bs-green);
  font-size: 16px;
  font-weight: 600;
}
.time-content .statuspending {
  color: var(--bs-pink);
  font-size: 16px;
  font-weight: 600;
}
.time-content .link {
  color: var(--bs-node-light-blue);
  padding: 0 5px;
}
.timeline ul li:nth-child(odd):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  right: -6px;
  background: var(--bs-node-violet);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.2);
}
.timeline ul li:nth-child(even):before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: -4px;
  background: var(--bs-node-violet);
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.2);
}
.timeline ul li h3 {
  padding: 0;
  margin: 0;
  color: var(--bs-node-violet);
  font-weight: 600;
}
.timeline ul li p {
  margin: 10px 0 0;
  padding: 0;
}
.timeline ul li .time h4 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #fff;
}

.timeline ul li:nth-child(odd) .time {
  position: absolute;
  top: 5px;
  right: -167px;
  margin: 0;
  padding: 10px 20px;
  color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 0 3px var(--bs-node-violet);
}
.timeline ul li:nth-child(even) .time {
  position: absolute;
  top: 5px;
  left: -167px;
  margin: 0;
  padding: 10px 20px;
  color: #fff;
  border-radius: 25px;
  box-shadow: 0 0 0 3px var(--bs-node-violet);
}
@media (max-width: 1150px) {
  .timeline {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .timeline ul li {
    list-style: none;
    position: relative;
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
  }
  .timeline {
    width: 100%;
    padding-bottom: 0;
  }
  .timeline-container h1 {
    font-size: 40px;
    text-align: center;
  }
  .timeline:before {
    left: 20px;
    height: 100%;
  }
  .timeline ul li:nth-child(odd),
  .timeline ul li:nth-child(even) {
    width: 100%;
    text-align: left;
    padding-left: 50px;
    padding-bottom: 50px;
  }
  .timeline ul li:nth-child(odd):before,
  .timeline ul li:nth-child(even):before {
    top: -18px;
    left: 16px;
  }
  .timeline ul li:nth-child(odd) .time,
  .timeline ul li:nth-child(even) .time {
    top: -30px;
    left: 50px;
    right: inherit;
  }
  .timeline ul li h3 {
    padding-top: 15px;
  }
}

.time_line_link {
  color: var(--bs-node-pink);
}

.time_line_link:hover {
  border-bottom: 2px solid var(--bs-pink);
}
