.theme-nav {
  background: transparent;
  position: absolute;
  width: 100%;
  padding: 1% 4%;
}
.theme-nav .theme-nav-logo {
  max-width: 350px;
  width: 100%;
}

.theme-nav-left {
  flex: 1;
}
.theme-nav-list {
  list-style: none;
}

.theme-nav-list-item {
  max-width: 300px;
  min-width: 180px;
}

.theme-nav-right-list-item {
  min-width: 80px;
  text-align: center;
  padding: 0 10px;
}

.theme-nav-link {
  text-decoration: none !important;
  cursor: pointer;
  margin: 0px 0px 0px 30px;
}

.theme-nav .theme-nav-toggle {
  display: none;
}

.theme-nav .theme-nav-toggle .toggle-icon {
  color: #fff;
  font-size: 40px;
}

.theme-sub-nav {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.theme-sub-nav .discrod-icon {
  width: 50px;
  height: 50px;
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-discord.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.theme-sub-nav .discrod-icon:hover {
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-discord-hover.png');
}

.theme-sub-nav .twitter-icon {
  width: 50px;
  height: 50px;
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-twitter.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.theme-sub-nav .twitter-icon:hover {
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-twitter-hover.png');
}

.theme-sub-nav .instagram-icon {
  width: 50px;
  height: 50px;
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-instagram.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.theme-sub-nav .instagram-icon:hover {
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/icon-instagram-hover.png');
}

.theme-dropdown-menu {
  background: rgb(255, 255, 255);
  width: 200px;
  position: absolute;
  top: 45px;
  list-style: none;
  text-align: start;
  border-radius: 5px;
  left: 40px;
  z-index: 100;
  font-size: 15px;
}
.theme-dropdown-menu .theme-dropdown-box {
  border-bottom: 1px solid #eee;
  padding: 5px;
}
.theme-dropdown-menu .theme-dropdown-box:last-child {
  border-bottom: none;
  padding: 5px;
}
.theme-dropdown-menu .theme-dropdown-box .theme-dropdown-link {
  text-decoration: none;
  /* color: #000 */
}
.theme-dropdown-menu .theme-dropdown-box .theme-dropdown-link:hover .them-nav-link-text {
  text-decoration: none;

  color: var(--theme-purple);
}
.theme-dropdown-menu .theme-dropdown-box .theme-dropdown-link .them-nav-link-text {
  font-weight: bold;
}
.theme-nav-drawer-list {
  list-style: none;
}
.theme-nav-drawer-list .theme-nav-right-list-item {
}
.theme-nav-drawer-link {
  text-decoration: none !important;
}
.them-nav-drawer-link-text {
  font-weight: 700;
  color: #fff;
  margin: 0;
  padding: 0;
}
.theme-nav-drawer-btm {
  background-color: none;

  width: 100%;
  margin-left: -5%;
  margin-top: -20px;
}
.theme-nav-drawer-btm-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.menu-logo-footer {
  text-align: center;
  margin: 0px auto;
}
.theme-nav-drawer-btm-list li {
  padding: 20px;
}

@media screen and (max-width: 1024px) {
  .theme-nav .theme-nav-toggle {
    display: block;
  }
  .theme-nav-right-side {
    display: none !important;
  }
  .theme-nav-left {
    display: none;
  }
  .theme-sub-nav {
    display: none;
  }
  .theme-nav .theme-nav-logo {
    max-width: 250px;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .demi-god-image {
    max-width: 60px;
  }
}

@media screen and (max-width: 660px) {
  .theme-nav .theme-nav-logo {
    max-width: 180px;
    width: 100%;
  }

  .drawer_body {
    margin-top: -20%;
  }
  .drawer_body img {
    width: 40%;
  }
  .rs-drawer-body {
    background-color: var(--theme-purple);
    background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
    background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  }
  .sub-list {
    padding-left: 10%;
  }
  .sub-list-first {
    margin-top: 5%;
  }
  .wait-button-margin {
    text-align: center;
    margin: 0px auto;
    padding-left: 30px;
  }
  .theme-nav {
    padding-top: 20px;
  }
  .theme-nav-toggle {
    padding-right: 15px;
  }
}
