.mint-detail {
  background-color: var(--theme-purple);

  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 40%, var(--theme-purple) 100%);
  width: 100%;
  margin: 0% auto;
}

.mint-detail-table h2 {
  font-size: 24px;
}

.mint-detail .mint-detail-content {
  max-width: 500px;
}

.mint-detail .mint-detail-table {
  border: 0.5px solid #6c7ddf;
  box-shadow: 10px 10px 2px #3c26af;
  width: 100%;
}

.desktop-tiles {
  padding-top: 0px;
}

.caption-small {
  font-size: 11px;
  padding-top: 30px;
}

.mint-captions {
  font-size: 15px;
}

.mint-detail .mint-detail-table td {
}

.mint-detail .mint-detail-table tr {
  display: flex;
  align-items: center;
}

.mint-detail .mint-detail-table tr:nth-child(even) {
  background-color: rgba(88, 96, 208, 0.6);
  border-top: 0.5px solid #6c7ddf;
}

.mint-detail .mint-detail-table tr:nth-child(3) {
  border-top: 0.5px solid #6c7ddf;
}

.mint-modal {
  background-color: #fff;
  color: var(--theme-purple);
}

.mint-dates p {
  color: #fff;
  padding: 0px !important;
  margin: 0px !important;
}

.spot-input {
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  border: 0;
  outline: 0;
  color: #000;
}

.spot-input::-webkit-input-placeholder {
  font-size: 14px;
  color: #000;
  font-family: Andale;
  text-align: center;
}

.spot-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  color: #000;
  font-family: Andale;
  text-align: center;
}

.spot-input::placeholder {
  font-size: 14px;
  color: #000;
  font-family: Andale;
  text-align: center;
}

.search-btn {
  padding: 10px;
  width: 100%;
  background-color: #fffc70;
  font-size: 25px;
  border-radius: 10px;
  margin-top: 20px;
}

@media screen and (min-width: 400px) {
}

@media screen and (min-width: 1200px) {
  .mint-detail .mint-detail-table {
    border: 0.5px solid #6c7ddf;
    box-shadow: 10px 10px 2px #3c26af;
    width: 100%;
    font-size: 1.5em;
  }

  .mint-detail .mint-detail-table td {
    margin-right: 20px;
  }

  .mint-detail .mint-detail-table tr {
    display: flex;
    align-items: center;
    padding-left: 3%;
  }
}

@media screen and (max-width: 480px) {
  .pushable-white .front-white {
    font-size: 1.5rem !important;
  }

  .caption-small {
    padding-top: 30px;
  }

  .mint-dates {
    font-size: 14px;
  }

  .mint-dates p {
    color: #fff;
    padding: 0px !important;
    margin: 0px !important;
  }

  .mint-detail-table h2 {
    font-size: 22px;
  }

  .mint-captions {
    font-size: 12px;
  }
}
