.video_section {
  padding: 80px 0;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

.video_section .title {
  font-size: 80px;
  color: #fff;
  font-family: destroyer;
  line-height: 50px;
}

.video_section .description {
  font-size: 35px;
}

.video_section .button_box {
  display: flex;
  justify-content: center;
}
.video_section .video_placeholder {
  cursor: pointer;
}

.video_section .title-image {
  width: 60%;
}

.video_section .video_container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  min-height: 300px;
}

@media screen and (min-width: 1125px) {
  .video_section .icon_image {
    width: 150px;
  }
  .video_section .description {
    font-size: 24px;
  }

  .video_section .title {
    font-size: 50px;
  }
  .caption-plain-text {
    font-size: 16px;
    padding: 0px 60px;
  }
}
