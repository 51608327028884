.contribute_card {
  min-height: 450px;
  padding: 5%;
  /* 
  margin: 5%; */

  max-width: 500px;
  margin: 0 auto;
}

.contribute_card:hover {
}

.member-title {
  padding-top: 10% !important;
  font-size: 140px !important;
}
.contribute_card .logos {
  display: flex;
  justify-content: flex-start;
  padding: 30px 0;
}
.coin_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 480px) {
  .post-slide img {
    width: 100%;
  }
  .member-title {
    font-size: 70px !important;
  }
  .throne-holder-text {
    padding: 5% 10% 0 10% !important;
  }
}
