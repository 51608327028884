.play-in {
  /* background-color: var(--theme-purple); */
  padding: 180px 0;
  background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/cave-boy-wallpaper-gneeks-classx.jpg');
  background-size: cover;
  background-size: 1800px 4150px;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 4000px;

}

.play-in .play-in-title {
  font-size: 150px;
  margin: 0;
  padding: 0;
}

.play-in .play-in-subtitle {
  color: var(--theme-yellow);
  text-align: center;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
  width: 70%;
  margin: 0px auto;
  margin-top: 2%;
}

.play-in .buy-area-outline-btn-box {
  display: flex;
  flex-direction: row;
}

.play-in .buy-area-outline-btn-box .buy-area-outline-btn {
  border: 2px solid #fff;
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-family: Andale;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  text-transform: uppercase;
}

.class-copy-body {
  width: 80%;
  margin-left: 10%;
}

@media screen and (max-width: 760px) {
  .play-in .buy-area-outline-btn-box {
    display: flex;
    flex-direction: column;
  }
  .play-in .play-in-title {
    font-size: 16vw;
    line-height: 60px;
  }

  .play-in .play-in-subtitle {
    width: 70%;
    margin: 0px auto;
  }

  .play-in-subtitle {
    margin-top: 10% !important;
    text-align: left !important;
    font-size: 18px !important;
  }

  .play-in {
    /* background-color: var(--theme-purple); */
    padding: 0px 0;
    background-image: url('https://nodechron.s3.us-east-2.amazonaws.com/node-cdn-images/gneeks/cave-boy-wallpaper-gneeks-classx.jpg');
    background-size: cover;
    background-size: 500px 1150px;
    background-repeat: no-repeat;
    background-position: bottom;
    height: 2850px;
      background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  }

  .class-copy-body p {
    font-size: 18px !important;
  }
}
