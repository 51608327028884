.comics_hero {
  padding: 150px 0;
}

.comics_hero .logo {
  width: 400px;
}

.comics_hero .description {
  font-size: 40px;
}
.comics_hero .btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.big-font {
  font-size: 120px;
  margin-top: -40px;
}
.yellow-font {
  background: -webkit-linear-gradient(#efc20c, #fffc0e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
  margin-top: 40px;
}

.comics_hero .btn_container button {
  font-size: 30px;
  display: block;
}

.rounded-image {
  border-radius: 5px;
  margin-top: -20px;
}

.image-title {
  width: 40%;
}

.react-parallax-bgimage {
  margin-top: -50px;
}

@media screen and (max-width: 480px) {
  .image-title {
    width: 70%;
  }
  .comics_hero {
    padding: 5px;
    padding-top: 80px;
    background-color: var(--bs-node-dark);
  }
  .react-parallax-bgimage {
    width: 480px !important;
    height: 210px !important;
  }
  .btn_container {
  }
  .comics-video-placeholder {
    width: 80%;
    text-align: center;
    margin: 0px auto;
  }
  .comics_hero .btn_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 1% 0;
  }
  .caption {
    width: 80%;
    text-align: center;
    margin: 0px auto;
    padding-bottom: 30px;
    padding-top: 20px;
  }

  .big-font {
    font-size: 50px;
    margin-top: -30px;
  }
  .yellow-font {
    background: -webkit-linear-gradient(#efc20c, #fffc0e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 25px;
    margin-top: 40px;
  }
}
