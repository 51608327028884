.roadmap-hero-title {
  font-size: 15vh;
}

.roadmap-h3 {
  font-family: burbank;
  color: #000;
  text-transform: uppercase;
  font-size: 50px;
  padding: 20px 0px 20px 0px;
}

.body-roadmap {
  width: 600px;
  text-align: center;
  margin: 0px auto;
}

@media screen and (max-width: 480px) {
  .roadmap-hero-title {
    font-size: 10vh;
    margin-top: -10% !important;
  }

  .timeline-hero-title {
    font-size: 25vh;
  }

  .body-roadmap {
    width: 230px;
    text-align: center;
    margin: 0px auto;
  }
}
