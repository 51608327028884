.preview_section {
  padding: 180px 0;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 30%, var(--theme-purple) 100%);
}

.preview_section .logo {
  width: 400px !important;
  margin-bottom: 40px;
  padding-top: 5%;
}

.preview_section_strips {
  padding: 2% 0;
  background-color: var(--bs-node-dark);
}

@media only screen and (max-width: 600px) {
  .preview_section .logo {
    width: 200px !important;
    margin-bottom: 40px;
    padding-top: 5%;
  }
  .preview_section {
    padding: 10% 0;
  }
  .caption-text {
    font-size: 14px;
    padding: 0px 20px;
  }
}
.owl-theme .owl-nav {
  display: inline-block;
  visibility: hidden;
}
