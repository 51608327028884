.prop-card {
  background-color: #fff;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  display: flex;
  opacity: 0.3;
}
.prop-card .prop-card-content {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.prop-card .prop-card-content .prop-card-title {
  font-size: 25px;
  text-transform: uppercase;
  color: var(--theme-purple);
  margin: 0;
}
.prop-card .prop-card-content .prop-card-description {
  color: #000;
  font-weight: 400;
  font-family: courier;
  font-size: 18px;
  margin: 0;
}

.prop-card .prop-card-content .prop-card-btn {
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 20px;
  font-weight: 500;
}

.prop-card .prop-vote-card {
  flex: 1;
  max-width: 150px;
  max-height: 150px;
  min-width: 150px;
  min-height: 150px;
  margin: 5px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.prop-card .prop-vote-card .vote-count {
  color: #fff;
  font-size: 70px;
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.prop-card .prop-vote-card .vote-type {
  color: #fff;
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.prop-card .prop-vote-card.prop-like {
  background-color: #53952d;
}

.prop-card .prop-vote-card.prop-dis-like {
  background-color: #ab3555;
}

.prop-card .prop-vote-ends-in {
  text-align: center;
  color: #000;
  padding: 2px 0;
  font-weight: 400;
  text-transform: uppercase;
}
.prop-card .user-icon-liked {
  border-radius: 100%;
  background-color: #53952d;
  color: #fff;
  text-transform: uppercase;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}
.prop-card .user-icon-dis-liked {
  border-radius: 100%;
  background-color: #ab3555;
  color: #fff;
  text-transform: uppercase;
  min-width: 50px;
  min-height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.throne-image {
  width: 20%;
}
.throne-image img {
  width: 80%;
}

@media screen and (max-width: 760px) {
  .votes {
    display: none !important;
  }
  .throne-image img {
    width: 60%;
  }
}
