.proposals-hero {
  padding-top: 150px;

  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}
.proposals-hero .proposals-hero-sub-title {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.proposals-hero .proposals-hero-title {
  font-size: 190px;
  margin: 0;
  padding: 0;
}

.under-co {
  background-color: #fff;
  border-radius: 5px;
  padding: 2%;
  margin-top: 2%;
  text-align: center;
}

.under-co a {
  color: red;
}

.proposals-hero .proposals-hero-description {
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.proposals-hero .proposals-categories {
  list-style: none;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin: 0;
}

.proposals-hero .proposals-categories::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}
.prev,
.next {
  border: 0;
  color: rgb(255, 255, 255);
  margin: 0 5px;
}

.prev .arrow-btn {
  min-width: 30px;
  min-height: 30px;
}

.next .arrow-btn {
  min-width: 30px;
  min-height: 30px;
}

.proposals-hero .proposals-categories li button {
  min-width: 150px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.proposals-hero .proposals-categories li button.cat-active {
  color: var(--theme-yellow);
}
@media screen and (max-width: 760px) {
  .proposals-hero .proposals-hero-title {
    font-size: 20vw;
  }
  .under-co {
    padding: 4% 6%;
    margin-top: 20px;
    font-size: 14px;
  }

  .proposals-hero .proposals-hero-sub-title {
    margin-top: -50px;
  }
}
