.tabs_section {
  padding: 8% 0 0 0;

  background-color: var(--bs-node-dark);
}
.tabs_section .gold_icon {
  width: 108px !important;
}

.image-caption-wrapper {
  width: 60%;
  text-align: center;
  margin: 0px auto;
  padding-bottom: 90px;
}

.tabs_section .tab_btn_container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 40px 0;
}

.tabs_section .tab_btn_container button {
  /* width: 100px; */
  color: #fff;
  padding-bottom: 5px;
  margin: 0 15px;
  border-bottom: 2px solid transparent;
  font-weight: bold;
  font-size: 25px;
}

.dapp-symbol {
  width: 60%;
}

.tabs_section .tab_btn_container .active_btn {
  /* width: 100px; */
  color: #f9e805;
  padding-bottom: 5px;
  margin: 0 15px;
  border-bottom: 2px solid #f9e805;
}

.tabs_section .title {
  color: #fff;
  text-align: center;
}

.tabs_section .description {
  color: #f9e805;
  text-align: center;
  font-size: 35px;
}

.branches {
  width: 60%;
}

@media screen and (max-width: 480px) {
  .tabs_section {
    background: var(--bs-node-dark);
    padding: 25% 0;
  }
  .title {
    line-height: 46px !important;
  }
  .description {
    width: 80%;
    margin: 0px auto;
    line-height: 35px;
  }
}
