.notification-bar {
  font-family: Courier;
}

.announce-news-wrapper {
  text-align: center;
}

@media screen and (max-width: 480px) {
  .announce-news-wrapper {
    width: 95%;
    padding: 12px 0 40px 20px !important;
    margin-left: -1% !important;
    text-align: center;
    margin-bottom: 10px;
    line-height: 20px;
  }
}
