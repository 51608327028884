.gneeks-characters {
  min-height: 100vh;
  margin-top: -5%;
  padding-bottom: 5%;
  padding-top: 120px;
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);

  /* position: relative; */
}
.gneeks-characters .gneeks-title {
  font-size: 150px;
  margin: 0;

  line-height: 80px;
  padding-top: 120px !important;
  padding-bottom: 40px !important;
}

.gneeks-characters .gneeks-subtitle {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.gneeks-characters .buy-area-outline-btn-box {
  display: flex;
  justify-content: center;
}

.gneeks-characters .buy-area-outline-btn-box .buy-area-outline-btn {
  border: 2px solid #fff;
  padding: 20px;
  width: 100%;
  margin: 5px;
  font-family: Andale;
  color: #fff;
  font-weight: 500;
  border-radius: 40px;
  text-transform: uppercase;
  max-width: 400px;
}
.as-seen-on {
  text-align: center;
  margin: 0px auto;
}

.rarity-logo1 {
  width: 40%;
  padding-top: 5%;
}
.rarity-logo2 {
  width: 90%;
  padding-top: 5%;
}

.partners-row {
  width: 40%;
  margin: 0px auto;
}

@media screen and (max-width: 480px) {
  .gneeks-characters {
    margin-top: -20%;
    padding-bottom: 5%;
    padding-top: 120px;

    /* position: relative; */
  }

  .gneeks-characters .gneeks-title {
    font-size: 150px;
    margin: 0;

    line-height: 80px;
    padding-top: 40px !important;
    padding-bottom: 20px !important;
  }
}

@media screen and (max-width: 760px) {
  .gneeks-characters .gneeks-title {
    font-size: 25vw;
  }
  .caption-wider {
    width: 110%;
    margin-left: -5% !important;
  }
}
