.features_section {
  padding: 5% 0;

  margin-top: -5%;

  background-color: var(--theme-purple);

  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}
.features_section .features_section_title {
  margin-top: 60px;
}
.features_section .section_logo {
  width: 100%;
  max-width: 250px;
}

.features_section .features_section_title {
  font-size: 140px !important;
}

.features_section .on_phone_small_text {
  font-size: 18px;
  max-width: 600px;
  margin: 50px auto;
}

.features_section .app_stores_links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.features_section .on_phone_small_text_green {
  font-size: 16px;
  max-width: 650px;
  margin: 50px auto;
}

@media (max-width: 1024px) {
  .features_section .features_section_title {
    font-size: 55px !important;
  }
}

@media (max-width: 760px) {
  .features_section .section_logo {
    width: 50%;
  }
  .features_section .features_section_title {
    font-size: 60px;
  }
  .features_section {
    padding: 5% 0;
    background-color: var(--bs-node-dark);
    margin-top: -5%;
  }
  .features_section .features_section_title {
    margin-top: 30px;
  }
}
