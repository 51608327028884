.classx-hero {
  background-color: var(--theme-purple);
  padding-top: 200px;
  padding-bottom: 10%;
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

@media screen and (max-width: 760px) {
  .classx-hero {
    padding-top: 100px;
  }
  .essay-text {
    font-size: 18px !important;
    padding: 10% 5% !important;
    text-align: left !important;
  }
}
