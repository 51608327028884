.faqs-section {
  padding: 80px 0;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

.faqs-container {
  max-width: 1300px;
  margin: 1rem auto;

  padding: 2.5rem 2rem;
}

.single-question {
  padding: 1rem 1.5rem;
  border: 0px solid #eae6eb;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  background: #fff;
}

.single-question h5 {
  text-transform: none;
  line-height: 1.5;
}

.single-question p {
  color: hsl(209, 34%, 30%);
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.single-question div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.single-question div h5 {
  margin-bottom: 0;
  color: #222;
}

.faqs-btn {
  background: transparent;
  border-color: transparent;
  width: 2rem;
  height: 2rem;
  background: #eae6eb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #b4345c;
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  min-width: 2rem;
}

.single-question-title {
  cursor: pointer;
  font-weight: normal;
  margin-top: -3px !important;
}
