.board-hero {
  padding-top: 200px;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  padding-left: 40px;
  padding-right: 40px;

}

.modal-header {
  color: #000 !important;
}

.modal-content {
background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
}

.board-hero .board-hero-sub-title {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0 0 5% 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

.board-hero .board-hero-title {
  font-size: 150px;
}

.board-hero .board-hero-description {
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}
.modal-content {
}

@media screen and (max-width: 760px) {
  .board-hero .board-hero-title {
    font-size: 20vw !important;
    margin-top: -5% !important;
  }
  .board-hero .board-hero-sub-title {
    margin-top: -15%;
  }
  .board-hero-description {
    width: 90%;
    margin: 0px auto !important;
    text-align: center;
    margin-left: 10%;
    padding: 0px 0px 20px 0px !important;
  }

  .modal-throne-image {
    width: 50% !important;
  }
  .modal-data-text {
    font-size: 11px;
  }
  .modal-content {
    border: none !important;
  }
}
