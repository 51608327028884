.gneeks-roadmap {
  background-color: var(--theme-purple);
  /* min-height: 100vh; */
  padding-top: 170px;
  background-color: var(--theme-purple);
  background: -webkit-radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  background: radial-gradient(circle, #4b62ea 0%, var(--theme-purple) 100%);
  position: relative;
  overflow: hidden;
}

.cloud1 {
  position: absolute;
  top: 5%;
  width: 250px;
  left: 5%;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.cloud2 {
  position: absolute;
  top: 5%;
  width: 150px;
  right: 15%;
  transform: translatey(0px);
  animation: float2 6s ease-in-out infinite;
}

.cloud3 {
  position: absolute;
  top: 5%;
  width: 250px;
  right: -5%;
  transform: translatey(0px);
  animation: float3 6s ease-in-out infinite;
}

.gneeks-roadmap .gneeks-title {
  font-size: 190px;
  margin: 0;
  padding: 0;
}

.crew-gradient {
  background: linear-gradient(to bottom, transparent 50%, transparent 50%),
    radial-gradient(circle closest-side, #00bffb, transparent);
  position: relative;
  bottom: 0;
  padding-top: 6%;
  /* width: 70%; */
  text-align: center;
  margin: 0px auto;
  height: 100%;
  border-bottom: 1px solid #1e004b;
  width: 100%;
}

.gneeks-roadmap .gneeks-subtitle {
  color: var(--theme-yellow);
  text-align: center;
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  font-family: courier;
  font-weight: lighter;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .gneeks-roadmap .gneeks-title {
    font-size: 25vw;
  }

  .cloud1 {
    position: absolute;
    top: 5%;
    width: 250px;
    left: -25%;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
  }

  .cloud2 {
    position: absolute;
    top: 5%;
    width: 150px;
    right: 15%;
    transform: translatey(0px);
    animation: float2 6s ease-in-out infinite;
  }

  .cloud3 {
    position: absolute;
    top: 10%;
    width: 250px;
    right: -25%;
    transform: translatey(0px);
    animation: float3 6s ease-in-out infinite;
  }

  .peep-text {
    margin-bottom: -5% !important;
  }

  .date-text {
    font-size: 17px !important;
    width: 100%;
    margin-top: -10px !important;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float3 {
  0% {
    transform: translatey(0px);
  }
  30% {
    transform: translatey(-70px);
  }
  100% {
    transform: translatey(0px);
  }
}

@import url('https://fonts.googleapis.com/css?family=Mukta:300,400,700');
.tooltip,
.container__sources:before,
.container__build:before,
.container__deploy:before,
.container__deploy2:before {
  position: absolute;
  right: 0;
  bottom: 100%;
  color: #fff;
  background-color: var(--theme-magenta);
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 0.25rem 0.75rem;
  border-radius: 2.5px;
}
.card,
.container__sources div,
.container__build div {
  line-height: 2;
  background: #fff;
  padding: 1.2rem 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px #e6e6e6;
}
.pfp-gif {
  width: 400px;
}
.book-one-game {
  width: 400px;
}
.school {
  width: 400px;
}

.calendar {
  width: 20px;
  text-align: left;
  margin: 0px auto;
}

.docs-heading {
  font-family: 'AstounderSquaredBold';
  padding-bottom: 60px;
  margin-top: -7%;
  text-transform: uppercase;
  color: #fff;
  font-size: 90px;
  filter: drop-shadow(3px 6px 0px #281c6b);
}
ul {
  padding: 0px 20px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  width: 100%;
  color: #5f39dd;
  background: #610dde;
}
.container-docs {
  margin: 5vh 2.5vw;
  padding: 5vh 0;
  background: #610dde;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.container-docs svg {
  height: 5rem;
}
.container-docs svg line {
  stroke: #fff;
  stroke-width: 3px;
  stroke-linecap: round;
  stroke-dasharray: 2px 20px;
  animation: animateline 5s linear both infinite;
}
.rmap-h3 {
  font-size: 1.1rem;
  color: #411fb2;
  font-weight: bold;
}
.rmap-p {
  font-size: 0.95rem;
  font-weight: 300;
}
.container__sources {
  display: flex;
  border-radius: 8px;
  padding: 1.5rem;
  background: #f9f9f9;
  position: relative;
}
.container__sources:before {
  content: 'Phases';
}
.container__sources div {
  text-align: left;
  margin: 0 1rem;
}
.container__build {
  padding: 10vh 10vw;
  border-radius: 8px;
  background: #f9f9f9;
  position: relative;
}
.container__build:before {
  content: 'current phase';
}
.container__build div {
  margin: 2rem 0;
}
.container__build div svg {
  width: 4rem;
  height: auto;
  fill: #5f39dd;
}
.container__deploy {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
}

.container__deploy:before {
  content: 'phase 2';
}

.container__deploy2 {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  position: relative;
}

.container__deploy2:before {
  content: 'phase 3';
}

@media (max-width: 700px) {
  .container__sources {
    flex-direction: column;
  }
  .container__sources div {
    margin: 1rem 0;
  }
}
@-moz-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -5rem;
  }
}
@-webkit-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -5rem;
  }
}
@-o-keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -5rem;
  }
}
@keyframes animateline {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -5rem;
  }
}
