.new_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 4% 4% 4%;
  position: absolute;
  width: 100%;
  height: 80px;
  z-index: 1;
}
._white {
  background-color: var(--bs-node-dark);
  z-index: 1;
}
.rs-drawer-body-close {
  display: none !important;
}
.rs-drawer-body {
  padding: 10% 10% !important;
  backgrund-color: var(--bs-node-dark);
}
.drawer_close_btn {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.new_nav .logo_box {
  flex: 2;
}
.new_nav .logo_box ._logo {
  margin-top: 0px;
  width: 150px;
  display: inline;
}

.new_nav .new_nav_items {
  flex: 4;
  display: flex;
  margin: 0;
  list-style: none;
  justify-content: center;
  margin-top: 12px;
}

.new_nav .new_nav_items .new_nav_item {
  border-bottom: 4px solid transparent;
  margin: 0 10px;
  padding-bottom: 5px;
}
.new_nav .new_nav_items .new_nav_item .new_nav_link {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
}
.new_nav .new_nav_items .new_nav_item .new_nav_link_black {
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  font-size: 16px;
}
.new_nav .new_nav_items .new_nav_item_active {
  border-bottom: 4px solid yellow;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

.new_nav .new_nav_items .new_nav_item:hover {
  border-bottom: 4px solid yellow;
}
.new_nav .right_side_nav {
  display: flex;
  justify-content: flex-end;
  flex: 2;
  align-items: center;
}
.new_nav .right_side_nav .sign_btn {
  width: 100px;
  font-weight: 500;
  color: #fff;
  height: 30px;
}

.new_nav .right_side_nav .sign_btn_black {
  width: 100px;
  font-weight: 500;
  color: #000;
  height: 30px;
}
.new_nav .right_side_nav .sign_btn_theme {
  width: 100px;
  font-weight: 500;
  color: #fff;
  height: 30px;
}
.new_nav .wallet_btn_box {
  display: flex;
  align-items: center;
}
.new_nav .wallet_btn_box .connect_wallet_btn {
  background-image: linear-gradient(#f0c70c, #fffc0e);
  padding: 15px 30px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 20px;
}

.new_nav .toggle_btn {
  display: none;
}

.mobile_link {
  text-decoration: none;
  color: #fff;
}
.new_nav .toggle_btn ._nav_bar_icon {
  color: #fff;
  font-size: 30px;
}
.new_nav .toggle_btn ._nav_bar_icon_theme {
  color: #fff;
  font-size: 30px;
}

.new_nav .toggle_btn ._nav_bar_icon_black {
  color: #000;
  font-size: 30px;
}
@media (max-width: 1024px) {
  .new_nav {
    padding: 30px 30px 30px 30px;
  }
  .new_nav .wallet_btn_box {
    display: none;
  }
  .new_nav .new_nav_items {
    display: none;
  }
  .new_nav .toggle_btn {
    display: block;
  }
  .right_side_nav {
    display: none;
  }
}

.ropdown-menu {
  background: rgb(255, 255, 255);
  width: 200px;
  position: absolute;
  top: 85px;
  list-style: none;
  text-align: start;
  border-radius: 5px;
  padding: 10px;
}
.ropdown-menu li {
  /* background: #1888ff; */
  cursor: pointer;
}

.ropdown-menu li:hover {
  /* background: #5cabff; */
}

.ropdown-menu.clicked {
  display: none;
}

.drop-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #000;
  padding: 5px;
}

.drawer_body {
  display: flex;
  flex-direction: column;
  /* max-height: 100vh; */
}
.drawer_body .mobile_menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #fff;
  justify-content: center;
  text-align: center;
}
.drawer_body .mobile_menu .new_nav_item {
  text-align: center;
  border-bottom: 4px solid transparent;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.drawer_body .mobile_menu .new_nav_item_active .mobile_link {
  text-align: center;
  border-bottom: 4px solid yellow;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  text-align: center;
}

.drawer_body .mobile_menu .logo_mobile {
  width: 50px;
}
.drawer_body .drawer_btn_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20%;
}
.new_nav .logo_box .mobile_logo {
  display: none !important;
}

@media screen and (max-width: 375px) {
}

@media screen and (max-width: 1024px) {
  .bg_dark {
    background-color: #020105;
  }
  .new_nav .right_side_nav .sign_btn {
    color: #fff;
    font-size: 14px;
  }
  .new_nav .toggle_btn ._nav_bar_icon {
    color: #fff;
    font-size: 30px;
  }
  .new_nav .toggle_btn ._nav_bar_icon_black {
    color: #000;
    font-size: 30px;
  }
  .new_nav .toggle_btn ._nav_bar_icon_theme {
    color: #fff;
    font-size: 30px;
  }
}

@media screen and (max-width: 480px) {
  .new_nav {
    padding: 30px 30px 30px 30px;
  }
}

@media screen and (max-width: 525px) {
  .new_nav .logo_box .mobile_logo {
    display: block !important;
    margin-left: -10px;
  }
  .new_nav .logo_box .desktop_logo {
    display: none !important;
  }
  .short_description {
    width: 330px !important;
    text-align: center;
    margin: 0px auto;
    margin-top: 14px !important;
  }
  .new_nav .right_side_nav .sign_btn_theme {
    width: 100px;
    font-weight: 500;
    color: #070640;
  }
  .new_nav .toggle_btn ._nav_bar_icon_theme {
    color: #070640;
    font-size: 30px;
  }
  .toggle_btn {
    margin-right: 0%;
  }
}
